import React from "react";
import { Link } from "react-router-dom";
import css from "./Breadcrumbs.module.css";

const Breadcrumbs = ({ listing }) => {
    const publicData = listing?.attributes?.publicData || {};

    const formatCategoryName = (category) => {
        if (!category) return "";
        return category
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const categoryLevels = Object.keys(publicData)
        .filter(key => key.startsWith("categoryLevel"))
        .sort((a, b) => a.localeCompare(b));

    const breadcrumbs = categoryLevels.map((level, index) => {
        const pathParams = categoryLevels
            .slice(0, index + 1)
            .map(levelKey => `pub_${levelKey}=${publicData[levelKey]}`)
            .join('&');

        return {
            name: formatCategoryName(publicData[level]),
            path: `/s?${pathParams}`
        };
    });

    return (
        <nav aria-label="breadcrumb">
            <ol className={css.breadcrumb}>
                {breadcrumbs.map((crumb, index) => (
                    <li key={index} className={css.breadcrumbItem}>
                        {crumb.path ? <Link to={crumb.path}>{crumb.name}</Link> : crumb.name}
                        {index < breadcrumbs.length - 1 && <span className={css.separator}>/</span>}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
